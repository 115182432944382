import React, { useState, useEffect } from 'react';
import { Grid, Box, Typography, CircularProgress, Alert, Button } from '@mui/material';
import { useLocation } from 'react-router-dom'; // For getting state passed via navigation
import PageLayout from '../../components/PageLayout';
import ImagesTable from './ImagesTable';
import ImagesSummaryTable from './ImagesSummaryTable';
import ImagesViolationChart from './ImagesViolationChart';

function ImagesPage() {
  const location = useLocation(); // Get location to access the state
  const streamName = location.state?.streamName; // Get the streamName passed via state
  
  const [imagesData, setImagesData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null); // Track the selected image for details

  const fetchData = async () => {
    if (!streamName) {
      console.error('No streamName available');
      return;
    }

    console.log(`Fetching data for streamName: ${streamName}`);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/images_api?streamName=${streamName}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Fetched data:', data);
      setImagesData(data);
      setLoading(false);
    } catch (err) {
      console.error('Failed to fetch images data:', err);
      setError('Failed to fetch images data.');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [streamName]);

  const handleImageClick = (image) => {
    setSelectedImage(image); // Set the selected image to show the detail view
  };

  const handleBackClick = () => {
    setSelectedImage(null); // Reset to show the table view again
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <PageLayout title={`Images Data for Stream ${streamName}`}>
      {selectedImage ? (
        <Box>
          {/* Show the image details view */}
          <Typography variant="h4" gutterBottom style={{ color: '#00C6FF', fontWeight: 'bold' }}>
            Image Details
          </Typography>
          <Box textAlign="center" p={3}>
            <Typography variant="h5" gutterBottom style={{ color: '#FFFFFF' }}>
              Detected Frame
            </Typography>
            <img
              src={`http://neom-restaurant-inspection.com/images/${streamName}/${selectedImage.frame_name}`}
              alt="Detected Frame"
              style={{ maxWidth: '100%', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' }}
            />
            <Typography variant="h6" color="textSecondary" paragraph style={{ color: '#8892B0', marginTop: '20px' }}>
              Detected Objects: {selectedImage.object_data.join(', ')}
            </Typography>
            <Typography variant="body2" color="textSecondary" style={{ color: '#8892B0' }}>
              Captured at: {new Date(selectedImage.timestamp).toLocaleString()}
            </Typography>
            <Button variant="contained" onClick={handleBackClick} style={{ backgroundColor: '#00C6FF', marginTop: '20px' }}>
              Back to Table
            </Button>
          </Box>
        </Box>
      ) : (
        <Box>
          {/* Show the table view */}
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Typography variant="h6" gutterBottom style={{ color: '#00C6FF', fontWeight: 'bold', textAlign: 'center' }}>
                Images Summary
              </Typography>
              <Box
                sx={{
                  height: '100%',
                  backgroundColor: '#1E293B',
                  borderRadius: '10px',
                  padding: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <ImagesSummaryTable violations={imagesData?.violations} />
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="h6" gutterBottom style={{ color: '#00C6FF', fontWeight: 'bold', textAlign: 'center' }}>
                Violation Percentages
              </Typography>
              <Box
                sx={{
                  height: '100%',
                  backgroundColor: '#1E293B',
                  borderRadius: '10px',
                  padding: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <ImagesViolationChart violations={imagesData?.violations} />
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ marginTop: '100px' }}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom style={{ color: '#00C6FF', fontWeight: 'bold' }}>
                  Images Data Table
                </Typography>
                <ImagesTable data={imagesData?.data} streamName={streamName} onImageClick={handleImageClick} />
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </PageLayout>
  );
}

export default ImagesPage;
