import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // For navigating with URL parameters
import {
  Box,
  Button,
  CircularProgress,
  Alert,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import PageLayout from '../../components/PageLayout';

export default function StreamsPage() {
  const [streamsData, setStreamsData] = useState([]); // Initialize as an empty array
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false); // Dialog state for adding a new stream
  const [newStream, setNewStream] = useState({ name: '', rtspUrl: '' }); // State to hold the new stream data

  const navigate = useNavigate(); // Initialize navigate

  // Fetch the streams data from the backend
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/streams`); // Fetch streams data from the backend
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setStreamsData(data);
      } catch (err) {
        console.error('Failed to fetch streams data:', err);
        setError('Failed to fetch streams data.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Handle opening and closing of the add stream dialog
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Handle the input fields for the new stream
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewStream({ ...newStream, [name]: value });
  };

  // Handle adding the stream (POST request to backend)
  const handleAddStream = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/streams`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          streamName: newStream.name,
          streamUrl: newStream.rtspUrl,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Assuming backend returns the new stream after successfully adding it
      const addedStream = await response.json();
      setStreamsData([...streamsData, addedStream]); // Add the new stream to the existing streams
      setOpen(false); // Close the dialog
      setNewStream({ name: '', rtspUrl: '' }); // Reset the form fields
    } catch (err) {
      console.error('Failed to add new stream:', err);
      setError('Failed to add new stream.');
    }
  };

  // Handle navigation to the ImagesPage with streamName via URL parameters
  const handleStreamClick = (stream) => {
    navigate('/images', { state: { streamName: stream.name } });
  };

  

  if (loading) return <CircularProgress />;

  return (
    <PageLayout title="CCTV Streams">
      {/* Button to add a new stream */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
        <Typography variant="h6" style={{ color: '#00C6FF', fontWeight: 'bold' }}>
          Available CCTV Streams
        </Typography>
        <Button variant="contained" onClick={handleClickOpen} style={{ backgroundColor: '#00C6FF', color: '#FFFFFF' }}>
          Add New Stream
        </Button>
      </Box>

      {/* Check if there's an error */}
      {error && <Alert severity="error">{error}</Alert>}

      {/* Table for displaying streams */}
      <TableContainer component={Paper} sx={{ backgroundColor: '#1E293B', borderRadius: '10px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: '#00C6FF', fontWeight: 'bold' }}>Stream ID</TableCell>
              <TableCell sx={{ color: '#00C6FF', fontWeight: 'bold' }}>Stream Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {streamsData.length > 0 ? (
              streamsData.map((stream) => (
                <TableRow key={stream.id} onClick={() => handleStreamClick(stream)} style={{ cursor: 'pointer' }}>
                  <TableCell sx={{ color: '#FFFFFF' }}>{stream.id}</TableCell>
                  <TableCell sx={{ color: '#FFFFFF' }}>{stream.name}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={2} sx={{ color: '#FFFFFF', textAlign: 'center' }}>
                  No streams available.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Dialog for adding a new stream */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Stream</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label="Stream Name"
            fullWidth
            variant="standard"
            value={newStream.name}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="rtspUrl"
            label="RTSP URL"
            fullWidth
            variant="standard"
            value={newStream.rtspUrl}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ color: '#FF0000' }}>
            Cancel
          </Button>
          <Button onClick={handleAddStream} style={{ color: '#00C6FF' }}>
            Add Stream
          </Button>
        </DialogActions>
      </Dialog>
    </PageLayout>
  );
}
