import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';

Chart.register(ArcElement, Tooltip, Legend);

export default function AlertChart({ chartData }) {
  // Log chart data to check if it is being passed correctly
  if (!chartData) {
    console.log('Chart Data is missing');
    return <div>Loading...</div>;
  }

  // Log the actual chart data received
  console.log('Chart Data received:', chartData);  // Should log totalSummary object with temperature_exceed, humidity_exceed, and air_quality_exceed keys

  // Ensure chartData has the keys we're expecting
  const { temperature_exceed, humidity_exceed, air_quality_exceed } = chartData;

  if (
    temperature_exceed === undefined ||
    humidity_exceed === undefined ||
    air_quality_exceed === undefined
  ) {
    console.error('One or more expected keys are missing from chartData');
    return <div>Error: Invalid chart data</div>;
  }

  // Data for each sensor type's violations (exceeded counts)
  const data = {
    labels: ['Temperature', 'Humidity', 'Air Quality'],  // Add the labels for each sensor type
    datasets: [
      {
        data: [
          temperature_exceed || 0,  // Violations for temperature
          humidity_exceed || 0,     // Violations for humidity
          air_quality_exceed || 0   // Violations for air quality
        ],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],  // Colors for each sensor type
        hoverBackgroundColor: ['#FF3B4D', '#2D93F9', '#FFBF47'],
        borderWidth: 2, // Add a border to make it look sharper
        borderColor: '#1E293B', // Match the background color for modern design
        hoverBorderColor: '#1E293B',
        cutout: '70%', // For the donut style look
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          color: '#FFFFFF',
          font: {
            size: 14,
            weight: 'bold',
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const total = tooltipItem.dataset.data.reduce((acc, val) => acc + val, 0);
            const value = tooltipItem.raw;
            const percentage = ((value / total) * 100).toFixed(2) + '%';
            return `${tooltipItem.label}: ${value} (${percentage})`;
          },
        },
        backgroundColor: '#1E293B',  // Tooltip background color
        titleColor: '#FFFFFF',  // Tooltip title color
        bodyColor: '#FFFFFF',  // Tooltip text color
        borderColor: '#00C6FF',  // Tooltip border color
        borderWidth: 1,
      },
    },
    animation: {
      animateScale: true,  // Makes it pop on load
      animateRotate: true,
    },
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '300px' }}>
      <Doughnut data={data} options={options} />
    </div>
  );
}
