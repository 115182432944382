import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { CircularProgress, Alert, Box } from '@mui/material';

export default function ReusableTable({ rows, columns }) {
  if (!rows || rows.length === 0) {
    return <Alert severity="error" sx={{ textAlign: 'center', backgroundColor: '#f44336', color: '#fff' }}>
             No data available.
           </Alert>;
  }

  return (
    <Box
      sx={{
        height: '400px',
        width: '100%',
        backgroundColor: '#1E293B',  // Dark background like the modern dashboard
        borderRadius: '10px',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.4)',  // Modern shadow
        padding: '20px',
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10, 20, 50]}
        checkboxSelection
        disableSelectionOnClick
        getRowId={(row) => row.id} // Ensure rows have unique ID
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
            backgroundColor: '#1E293B',  // Match the dark background theme
            color: '#FFFFFF',
            borderRadius: '10px',
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#334155',  // Darker header
            color: '#00C6FF',  // Bright text color for headers
            fontSize: '1.1rem',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            borderBottom: '2px solid rgba(224, 224, 224, 0.2)',
            padding: '10px 0',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: '1px solid rgba(224, 224, 224, 0.1)',
            color: '#FFFFFF',
            fontFamily: 'Roboto, sans-serif',
            fontSize: '0.9rem',
            backgroundColor: '#1E293B',  // Slightly darker row background
            padding: '10px',
          },
          '& .MuiDataGrid-row': {
            '&:nth-of-type(odd)': {
              backgroundColor: 'rgba(235, 235, 235, 0.1)',  // Alternating row colors
            },
            '&:nth-of-type(even)': {
              backgroundColor: 'rgba(255, 255, 255, 0.1)',  // Alternating row colors
            },
            '&:hover': {
              backgroundColor: '#334155',  // Hover effect
            },
          },
          '& .MuiCheckbox-root': {
            color: '#00C6FF',  // Modern checkbox color
          },
          '& .MuiDataGrid-footerContainer': {
            backgroundColor: '#1E293B',  // Footer background color
            color: '#00C6FF',
          },
          '& .MuiPaginationItem-root': {
            color: '#00C6FF',
          },
        }}
      />
    </Box>
  );
}