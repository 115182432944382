import React from 'react';
import { Container, Typography, Button, Box, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

function HomePage() {
  const theme = useTheme();

  return (
    <Container 
      maxWidth="xl" 
      style={{ 
        position: 'relative', 
        minHeight: '100vh', 
        backgroundColor: theme.palette.background.default,
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'center',
        padding: '0 2rem'
      }}
    >
      {/* Logos in the top corners */}

      <div style={{ position: 'absolute', top: 10, left: 10, backgroundColor: 'transparent'}}>
        <img src="/logo2.png" alt="EI Logo" style={{ width: '150px' }}  />
      </div>

      {/* Centered content */}
      <Box>
        <Typography variant="h2" gutterBottom style={{ color: '#00C6FF', fontWeight: 700 }}>
          Enterprise AI Solutions for Restaurant Monitoring
        </Typography>
        <Typography variant="h5" style={{ color: 'white', marginBottom: '2rem' }}>
          Optimize Your Restaurant Operations with Cutting-Edge Monitoring Solutions.
        </Typography>
        <Button
          variant="outlined"
          component={Link}
          to="/contact"  // Adjust to your contact page route
          sx={{
            color: 'white',
            borderColor: '#00C6FF',
            padding: '10px 20px',
            '&:hover': {
              backgroundColor: '#00C6FF',
              borderColor: '#0072FF',
            },
          }}
        >
          Get in touch
        </Button>
      </Box>

      <Box>
        {/* Add any graphic or design element as seen in the original screenshot */}
        <img src="/AI1.png" alt="AI Graphic" style={{ width: '300px', backgroundColor: 'transparent' }} />
./      </Box>
    </Container>
  );
}

export default HomePage;
