import React from 'react';
import { Typography } from '@mui/material';

function PageLayout({ title, children }) {
  return (
    <div
      style={{
        position: 'relative',
        backgroundColor: '#0A192F', // Match background to the rest of the app
        minHeight: '100vh', // Ensure it covers the full viewport height
        padding: '2rem',
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        style={{
          color: '#00C6FF',
          fontWeight: 'bold',
          fontFamily: 'Poppins, sans-serif',  // Consistent font styling
          textAlign: 'center',
        }}
      >
        {title}
      </Typography>
      {children}  {/* Render the children passed into the layout */}
    </div>
  );
}

export default PageLayout;
