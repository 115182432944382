import React, { useState, useEffect } from 'react';
import { Grid, Box, Typography, CircularProgress, Alert } from '@mui/material';
import PageLayout from '../../components/PageLayout';
import TemperatureTable from './TemperatureTable';
import SummaryTable from './SummaryTable';
import AlertChart from './AlertChart';

function TemperaturePage() {
  const [sensorData, setSensorData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/temperature`);  // Fetch combined data
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log('Fetched Sensor Data:', data);  // Log the fetched data
        setSensorData(data);  // Store the data received from the backend
        setLoading(false);  // Stop loading when data is fetched
      } catch (err) {
        console.error('Failed to fetch sensor data:', err);
        setError('Failed to fetch sensor data.');
        setLoading(false);
      }
    };

    fetchData();  // Fetch data on component mount
  }, []);  // Empty dependency array ensures this effect runs only once

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  console.log('Exceed Counts Passed to Chart:', sensorData?.exceedCounts);  // Log the exceed counts passed to the chart

  return (
    <PageLayout title="Sensor Data Overview">
      {/* First Grid Row for Summary and Chart */}
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Typography variant="h6" gutterBottom style={{ color: '#00C6FF', fontWeight: 'bold', textAlign: 'center' }}>
            Sensor Alerts Summary
          </Typography>
          <Box sx={{ height: '100%', backgroundColor: '#1E293B', borderRadius: '10px', padding: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <SummaryTable summaryData={sensorData?.fullSummary} />  {/* Pass summary data */}
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="h6" gutterBottom style={{ color: '#00C6FF', fontWeight: 'bold', textAlign: 'center' }}>
            Alerts Exceeding Limit
          </Typography>
          <Box sx={{ height: '100%', backgroundColor: '#1E293B', borderRadius: '10px', padding: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <AlertChart chartData={sensorData?.exceedCounts} />  {/* Pass exceedCounts data instead of totalSummary */}
          </Box>
        </Grid>
      </Grid>

      {/* Add more margin below the chart */}
      <Box sx={{ marginTop: '100px' }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom style={{ color: '#00C6FF', fontWeight: 'bold' }}>
              Sensor Data Table
            </Typography>
            <TemperatureTable data={sensorData?.fullSensorData} />  {/* Pass full sensor data */}
          </Grid>
        </Grid>
      </Box>
    </PageLayout>
  );
}

export default TemperaturePage;
