import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';

Chart.register(ArcElement, Tooltip, Legend);

export default function ImagesViolationChart({ violations }) {
  const totalViolations = violations.maskoff_violations + violations.no_glove_violations + violations.no_hairnet_violations;

  const data = {
    labels: ['Mask Off', 'No Glove', 'No Hairnet'],
    datasets: [
      {
        data: [
          (violations.maskoff_violations / totalViolations) * 100,
          (violations.no_glove_violations / totalViolations) * 100,
          (violations.no_hairnet_violations / totalViolations) * 100,
        ],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
        hoverBackgroundColor: ['#FF3B4D', '#2D93F9', '#FFC107'],
        borderWidth: 2,
        borderColor: '#1E293B',
        hoverBorderColor: '#1E293B',
        cutout: '70%',
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          color: '#FFFFFF',
          font: {
            size: 14,
            weight: 'bold',
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const total = tooltipItem.dataset.data.reduce((acc, val) => acc + val, 0);
            const value = tooltipItem.raw;
            const percentage = `${value.toFixed(2)}%`;
            return `${tooltipItem.label}: ${percentage}`;
          },
        },
        backgroundColor: '#1E293B',
        titleColor: '#FFFFFF',
        bodyColor: '#FFFFFF',
        borderColor: '#00C6FF',
        borderWidth: 1,
      },
    },
    animation: {
      animateScale: true,
      animateRotate: true,
    },
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '300px' }}>
      <Doughnut data={data} options={options} />
    </div>
  );
}
