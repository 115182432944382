import React, { useState, useEffect } from 'react';
import { Grid, Box, Typography, CircularProgress, Alert } from '@mui/material';
import PageLayout from '../../components/PageLayout';
import ReviewsTable from './ReviewsTable';
import ReviewsSummaryTable from './ReviewsSummaryTable';
import ReviewsRatingChart from './ReviewsRatingChart';

function ReviewsPage() {
  const [reviewData, setReviewData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/reviews`);  // Fetch the reviews data
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log('Fetched review data:', data); // Log fetched data
        setReviewData(data);
        setLoading(false);
      } catch (err) {
        console.error('Failed to fetch review data:', err);
        setError('Failed to fetch review data.');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  console.log('Passing to ReviewsTable:', reviewData?.data);  // Log data passed to ReviewsTable

  return (
    <PageLayout title="Restaurant Reviews">
      {/* First Grid Row for Summary and Chart */}
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Typography variant="h6" gutterBottom style={{ color: '#00C6FF', fontWeight: 'bold', textAlign: 'center' }}>
            Reviews Summary
          </Typography>
          <Box sx={{ height: '100%', backgroundColor: '#1E293B', borderRadius: '10px', padding: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <ReviewsSummaryTable metaData={reviewData?.metaData} topRestaurants={reviewData?.topRestaurants} />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="h6" gutterBottom style={{ color: '#00C6FF', fontWeight: 'bold', textAlign: 'center' }}>
            Ratings Distribution
          </Typography>
          <Box sx={{ height: '100%', backgroundColor: '#1E293B', borderRadius: '10px', padding: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <ReviewsRatingChart topRestaurants={reviewData?.topRestaurants} />
          </Box>
        </Grid>
      </Grid>

      {/* Second Grid Row for Reviews Table */}
      <Box sx={{ marginTop: '100px' }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom style={{ color: '#00C6FF', fontWeight: 'bold' }}>
              Restaurant Reviews Table
            </Typography>
            <ReviewsTable data={reviewData?.data} />  {/* Pass the data to ReviewsTable */}
          </Grid>
        </Grid>
      </Box>
    </PageLayout>
  );
}

export default ReviewsPage;
