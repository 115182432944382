import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

export default function ImagesSummaryTable({ violations }) {
  if (!violations) {
    return null; // Return nothing if no violations data is available
  }

  return (
    <TableContainer component={Paper} sx={{ maxWidth: '100%', backgroundColor: '#1E293B', borderRadius: '10px', padding: '20px' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ color: '#00C6FF', fontWeight: 'bold' }}>Metric</TableCell>
            <TableCell sx={{ color: '#00C6FF', fontWeight: 'bold' }}>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={{ color: '#FFFFFF' }}>Total Images</TableCell>
            <TableCell sx={{ color: '#FFFFFF' }}>{violations.total_images}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ color: '#FFFFFF' }}>Mask Off Violations</TableCell>
            <TableCell sx={{ color: '#FFFFFF' }}>{violations.maskoff_violations}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ color: '#FFFFFF' }}>No Glove Violations</TableCell>
            <TableCell sx={{ color: '#FFFFFF' }}>{violations.no_glove_violations}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ color: '#FFFFFF' }}>No Hairnet Violations</TableCell>
            <TableCell sx={{ color: '#FFFFFF' }}>{violations.no_hairnet_violations}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
