import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

export default function ReviewsSummaryTable({ metaData, topRestaurants }) {
  console.log('MetaData:', metaData); // Log the metadata passed
  console.log('TopRestaurants:', topRestaurants); // Log the top restaurants array passed

  const bestRestaurant =
    topRestaurants.length > 0
      ? `${topRestaurants[0].name}: ${topRestaurants[0].overall_cleanliness.toFixed(2)}`
      : 'N/A';

  const worstRestaurant =
    topRestaurants.length > 0
      ? `${topRestaurants[topRestaurants.length - 1].name}: ${topRestaurants[topRestaurants.length - 1].overall_cleanliness.toFixed(2)}`
      : 'N/A';

  console.log('Best Restaurant:', bestRestaurant); // Log the best restaurant value
  console.log('Worst Restaurant:', worstRestaurant); // Log the worst restaurant value

  return (
    <TableContainer
      component={Paper}
      sx={{
        maxWidth: '100%',
        backgroundColor: '#1E293B',
        borderRadius: '10px',
        padding: '20px',
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ color: '#00C6FF', fontWeight: 'bold' }}>Metric</TableCell>
            <TableCell sx={{ color: '#00C6FF', fontWeight: 'bold' }}>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={{ color: '#FFFFFF' }}>Number of Restaurants</TableCell>
            <TableCell sx={{ color: '#FFFFFF' }}>{metaData?.total_restaurants || 'N/A'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ color: '#FFFFFF' }}>Number of Comments</TableCell>
            <TableCell sx={{ color: '#FFFFFF' }}>{metaData?.total_comments || 'N/A'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ color: '#FFFFFF' }}>Best Restaurant</TableCell>
            <TableCell sx={{ color: '#FFFFFF' }}>{bestRestaurant}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ color: '#FFFFFF' }}>Worst Restaurant</TableCell>
            <TableCell sx={{ color: '#FFFFFF' }}>{worstRestaurant}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
