import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import HomePage from './pages/HomePage/HomePage';
import TemperaturePage from './pages/TemperaturePage/TemperaturePage';
import ReviewsPage from './pages/ReviewsPage/ReviewsPage';
import ImagesPage from './pages/ImagePage/ImagesPage';
import NavigationTabs from './components/NavigationTabs';
import StreamsPage from './pages/StreamsPage/StreamsPage';
import ImageDetail from './pages/ImagePage/ImageDetail';


const theme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#0A192F',
      paper: '#112240',
    },
    primary: {
      main: '#00C6FF',
    },
    secondary: {
      main: '#dc004e',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#8892B0',
    },
  },
  typography: {
    h4: {
      fontWeight: 600,
    },
    h1: {
      fontSize: '3rem',
      fontWeight: 700,
      color: '#FFFFFF',
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 600,
      color: '#00C6FF',
    },
    body1: {
      fontSize: '1rem',
      color: '#8892B0',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <NavigationTabs /> {/* This ensures tabs/navigation bar is visible on all pages */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/iot" element={<TemperaturePage />} />
          <Route path="/reviews" element={<ReviewsPage />} />
          <Route path="/Streams" element={<StreamsPage />} />
          <Route path="/images" element={<ImagesPage />} />
          <Route path="/images/:streamId/:frame_path" element={<ImageDetail />} />

        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
