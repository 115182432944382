import React from 'react';
import { AppBar, Toolbar, Tabs, Tab, Box, Button } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

function NavigationTabs() {
  const location = useLocation();
  const currentTab = location.pathname;

  return (
    <AppBar position="static" sx={{ backgroundColor: '#112240', boxShadow: 'none' }}>
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          <Tabs value={currentTab} indicatorColor="secondary">
            {/* Home Tab */}
            <Tab
              label="Home"
              component={Link}
              to="/"
              sx={{ color: 'white', textTransform: 'none', fontWeight: 'bold' }}
            />

            {/* Direct link to Temperature page */}
            <Tab
              label="IoT_Devices"
              component={Link}
              to="/iot"
              sx={{ color: 'white', textTransform: 'none', fontWeight: 'bold' }}
            />

            {/* Direct link to Reviews page */}
            <Tab
              label="Reviews"
              component={Link}
              to="/reviews"
              sx={{ color: 'white', textTransform: 'none', fontWeight: 'bold' }}
            />

            {/* Direct link to CCTV Monitoring page */}
            <Tab
              label="CcTV monitoring"
              component={Link}
              to="/Streams"
              sx={{ color: 'white', textTransform: 'none', fontWeight: 'bold' }}
            />
          </Tabs>
        </Box>

        {/* Get in Touch Button */}
        <Button
          variant="outlined"
          sx={{
            color: '#FFFFFF',
            borderColor: '#00C6FF',
            '&:hover': {
              backgroundColor: '#00C6FF',
              borderColor: '#0072FF',
            },
          }}
        >
          Get in touch
        </Button>
      </Toolbar>
    </AppBar>
  );
}

export default NavigationTabs;
