import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

export default function SummaryTable({ summaryData }) {
  if (!summaryData) return <div>Loading...</div>;

  return (
    <TableContainer component={Paper} sx={{ maxWidth: '100%', backgroundColor: '#1E293B', borderRadius: '10px', padding: '20px' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ color: '#00C6FF', fontWeight: 'bold' }}>Sensor Type</TableCell>
            <TableCell sx={{ color: '#00C6FF', fontWeight: 'bold' }}>Exceeded Count</TableCell>
            <TableCell sx={{ color: '#00C6FF', fontWeight: 'bold' }}>Normal Count</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {summaryData.length > 0 ? (
            summaryData.map((row, index) => (
              <TableRow key={index}>
                <TableCell sx={{ color: '#FFFFFF' }}>{row.sensor_type}</TableCell>
                <TableCell sx={{ color: '#FFFFFF' }}>{row.exceeded_count}</TableCell>
                <TableCell sx={{ color: '#FFFFFF' }}>{row.normal_count}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={3} sx={{ color: '#FFFFFF', textAlign: 'center' }}>
                No data available
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
