import React from 'react';
import { DataGrid } from '@mui/x-data-grid';

const columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  { field: 'sensor_id', headerName: 'Sensor ID', width: 150 },
  { field: 'sensor_name', headerName: 'Sensor Name', width: 150 },
  { field: 'value', headerName: 'Value', width: 150 },
  { field: 'alert', headerName: 'Alert', width: 180 },
  { 
    field: 'timestamp', 
    headerName: 'Timestamp', 
    width: 200 },

];

export default function TemperatureTable({ data }) {
  // Ensure we log the data being passed to this component
  console.log('TemperatureTable received data:', data);

  if (!data || data.length === 0) {
    return <p>No sensor data available.</p>;
  }

  return (
    <div style={{ height: 400, width: '100%', marginTop: '20px' }}>
      <DataGrid 
        rows={data} 
        columns={columns} 
        pageSize={5} 
        rowsPerPageOptions={[5, 10, 20]} 
        checkboxSelection 
        disableSelectionOnClick 
        getRowId={(row) => row?.id}  // Use row.id as the unique identifier
        sx={{
          backgroundColor: '#1E293B', // Dark background for modern look
          color: '#FFFFFF', // Text color
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#334155',
            color: '#00C6FF',
            fontWeight: 'bold',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: '1px solid rgba(224, 224, 224, 0.1)',
            color: '#FFFFFF',
          },
          '& .MuiDataGrid-row': {
            '&:nth-of-type(odd)': {
              backgroundColor: '#293548',
            },
            '&:hover': {
              backgroundColor: '#334155',
            },
          },
        }}
      />
    </div>
  );
}
