import React from 'react';
import ReusableTable from '../../components/ReusableTable';

const columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  { field: 'name', headerName: 'Name', width: 150 },
  { field: 'place_id', headerName: 'Place ID', width: 150 },
  { field: 'comment', headerName: 'Comment', width: 300 },
  { field: 'overall_cleanliness', headerName: 'Overall Cleanliness', width: 150 },
  { field: 'restroom_hygiene', headerName: 'Restroom Hygiene', width: 150 },
  { field: 'staff_hygiene', headerName: 'Staff Hygiene', width: 150 },
  { field: 'food_quality', headerName: 'Food Quality', width: 150 },
  { field: 'food_poisoning', headerName: 'Food Poisoning', width: 150 },
];

export default function ReviewsTable({ data }) {
  // Ensure the data has an 'id' field and log it for debugging
  const rows = data?.map((row, index) => ({
    ...row,
    id: row.id || index, // Use row.id or fallback to index as a unique identifier
  })) || [];

  console.log('Rows passed to ReusableTable:', rows); // Log to check data structure

  return <ReusableTable rows={rows} columns={columns} />;
}
