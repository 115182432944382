import React, { useState } from 'react';
import { Modal, Box, Typography } from '@mui/material';
import ReusableTable from '../../components/ReusableTable';
import { Alert } from '@mui/material';  // Import for fallback messages

const columns = (streamName, onImageClick) => [
  { 
    field: 'frame_name', 
    headerName: 'Frame Path', 
    width: 200,
    renderCell: (params) => (
      <span 
        onClick={() => onImageClick(params.row)}  // Use onImageClick to show details in the same page
        style={{ color: '#00C6FF', textDecoration: 'none', cursor: 'pointer' }}
      >
        {params.value}
      </span>
    ),
  },
  { field: 'object_data', headerName: 'Detection Info', width: 400 },
  { 
    field: 'timestamp', 
    headerName: 'Timestamp', 
    width: 200,
    valueGetter: (params) => {
      if (!params.value) {
        return 'No Date Available';
      }
      const date = new Date(params.value);
      return isNaN(date.getTime()) ? 'No Date Available' : date.toLocaleString();
    },
  },
];

export default function ImagesTable({ data, streamName }) {  // Accept onImageClick as a prop
  console.log('Original data received:', data);  // Log the original data received

  const [selectedImage, setSelectedImage] = useState(null);  // State to store the selected image
  const [open, setOpen] = useState(false);  // State to control modal open/close

  const handleImageClick = (row) => {
    setSelectedImage(row.frame_name); // Set the selected image frame_name
    setOpen(true);  // Open the modal
  };

  const handleClose = () => {
    setOpen(false);  // Close the modal
    setSelectedImage(null); // Clear the selected image
  };

  if (!data || data.length === 0) {
    console.error('No images data available:', data);  // Log if no data is available
    return (
      <Alert severity="error" sx={{ textAlign: 'center', backgroundColor: '#f44336', color: '#fff' }}>
        No images data available.
      </Alert>
    );
  }

  // Ensure each row has a unique id
  const rows = data?.map((row, index) => ({
    ...row,
    id: row.id || index,  // Fallback to index if `id` is missing
  }));

  console.log('Rows passed to ReusableTable:', rows);  // Log the generated rows

  return (
    <div>
      <ReusableTable rows={rows} columns={columns(streamName, handleImageClick)} />

      {/* Modal to display the selected image */}
      <Modal open={open} onClose={handleClose} aria-labelledby="image-modal-title">
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#1E293B',
            padding: 4,
            borderRadius: 2,
            boxShadow: 24,
          }}
        >
          <Typography id="image-modal-title" variant="h6" style={{ color: '#FFFFFF' }}>
            Detected Frame
          </Typography>
          {selectedImage && (
            <img
              src={`http://neom-restaurant-inspection.com/images/${streamName}/${selectedImage}`}  // Use streamName in the directory
              alt="Detected Frame"
              style={{ maxWidth: '100%', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' }}
            />
          )}
        </Box>
      </Modal>
    </div>
  );
}
